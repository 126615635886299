import React, { Suspense } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

import { loginRedirectPrivider } from '../../services/auth'
import SkeletonComponent from './components/SkeletonComponent'

interface Props extends RouteProps {
  layout: React.ComponentType<any>
  component: React.ComponentType<any>
  // eslint-disable-next-line react/require-default-props
  isAuth?: boolean
}

const RouteLayout = (props: Props): JSX.Element => {
  const token: string | null = localStorage.getItem('gov_access_token_siseci')
  const { layout: Layout, component: Component, path, isAuth } = props
  const { pathname, search } = useLocation()
  const url = pathname + search

  const isWorkspace = pathname.startsWith('/workspace/')

  if (isAuth && !token && isWorkspace) {
    localStorage.setItem('pathname_redirect_gov', url)
    const pathnameStorage = localStorage.getItem('pathname_redirect_gov')

    loginRedirectPrivider(pathnameStorage)
  } else if (isAuth && !token) {
    localStorage.setItem('pathname_redirect_gov', url)

    return <Redirect to="/" />
  }

  return (
    <Route
      path={path}
      render={(matchProps) => (
        <Suspense fallback={<SkeletonComponent />}>
          <Layout>
            <Component {...matchProps} />
          </Layout>
        </Suspense>
      )}
    />
  )
}

export default RouteLayout
